import React from "react"

import makeCarousel from "react-reveal/makeCarousel"

import Slide from "react-reveal/Slide"

import styled, { css } from "styled-components"
import Hero from "./hero2"
import Handwerk from "./Handwerk"
import Kunde from "./kunde"
import Meister from "./meister"
import Webseite from "./webseite"
import Wischer from "./wischer"

const slider2 = () => {
  const width = "100%",
    height = "100vh"
  const Container = styled.div`
    position: relative;
    overflow: hidden;
    width: ${width};
  `
  const Children = styled.div`
    width: ${width};
    position: relative;
    height: ${height};
  `

  const CarouselUI = ({ position, handleClick, children, total }) => {
    return (
      <Container>
        <Children>{children}</Children>
      </Container>
    )
  }
  const Carousel = makeCarousel(CarouselUI)

  return (
    <Carousel
      swipe={true}
      maxTurns={1}
      defaultWait={4000} /*wait for 1000 milliseconds*/
    >
      <Slide right>
        <Hero />
      </Slide>

      <Slide right>
        <Handwerk />
      </Slide>
      <Slide right>
        <Wischer />
      </Slide>
      <Slide right>
        <Meister />
      </Slide>
      <Slide right>
        <Kunde />
      </Slide>
      <Slide right>
        <Webseite />
      </Slide>
    </Carousel>
  )
}

export default slider2
