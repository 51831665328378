import React from "react"

const Hero = () => {
  const PageOrTemplate = ({ children, transitionStatus, entry, exit }) => {
    console.log(transitionStatus, entry, exit)
    return <div className={transitionStatus}>{children}</div>
  }
  return (
    <header className="header2">
      <div class="hero">
        <div className="hero-banner">
          <h3 class="hero-title">
            Lassen Sie uns zusammen Ihr Projekt entwickeln!
          </h3>
        </div>
      </div>
    </header>
  )
}

export default Hero
