import React from "react"

import { StaticImage } from "gatsby-plugin-image"
import Rotate from "react-reveal/Rotate"

const Meister = () => {
  return (
    <div>
      <header class="p-10 min-h-screen flex items-center justify-center bg-teal-400 fourth">
        <div className=" text-gray-600 px-6 lg:px-8 pt-12 pb-20">
          <div className="max-w-screen-xl mx-auto grid grid-cols-2 gap-8 lg:gap-24 md:items-center">
            <div className="col-span-2 md:col-span-1 flex flex-row space-x-2">
              <Rotate top right duration={3000}>
                <StaticImage
                  src="../assets/images/faller.png"
                  alt="portfolio"
                  className="hero-img-hoch"
                  placeholder="blurred"
                />
              </Rotate>
            </div>
            <div className="col-span-2 md:col-span-1">
              <h2 className="text-2xl sm:text-3xl  lg:text-4xl text-MedienwerkstattDarkBlue font-medium mb-6">
                Es ist noch kein Meister vom Himmel gefallen. Und bei Google
                ganz vorne gelandet. Wir unterstützen Sie dabei gesucht und auch
                gefunden zu werden
              </h2>
            </div>
          </div>
        </div>
      </header>
    </div>
  )
}

export default Meister
