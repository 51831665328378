import React from "react"
import Layout from "../components/Layout"

import Slider from "../components/slider2"

import Seo from "../components/Seo"
const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Webentwicklung Krefeld" />
      <main>
        <Slider />
      </main>
    </Layout>
  )
}

export default IndexPage
