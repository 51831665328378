import React from "react"

import Spin from "react-reveal/Spin"
import { StaticImage } from "gatsby-plugin-image"

const Kunde = () => {
  return (
    <div>
      <header class="p-10 min-h-screen flex items-center justify-center bg-teal-400 five">
        <div className=" text-gray-600 px-6 lg:px-8 pt-12 pb-20">
          <div className="max-w-screen-xl mx-auto grid grid-cols-2 gap-8 lg:gap-24 md:items-center">
            <div className="col-span-2 md:col-span-1 flex flex-row space-x-2">
              <Spin>
                <StaticImage
                  src="../assets/images/kunde.png"
                  alt="portfolio"
                  className="hero-img-hoch"
                  placeholder="blurred"
                />
              </Spin>
            </div>

            <div className="col-span-2 md:col-span-1">
              <h2 className="text-2xl sm:text-3xl  lg:text-4xl text-MedienwerkstattDarkBlue font-medium mb-6">
                Ein Kunde findet Sie online. Mit einer Webseite von der
                Medienwerstatt Niederrhein wird er auch Ihr Kunde! wieder.
              </h2>
            </div>
          </div>
        </div>
      </header>
    </div>
  )
}

export default Kunde
